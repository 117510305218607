<template>
  <main
    class="home"
    :style="{ backgroundImage: `url(${$baseUrl}images/home-bg.svg)` }"
  >
    <div class="home__logo">
      <img
        class="home__logo__img"
        :src="`${$baseUrl}images/home-logo.svg`"
        :alt="content.title"
      />
    </div>

    <router-link class="home__enter-btn" :to="{ name: 'landing' }">
      {{ content.ctaEnter }}

      <DogEar class="home__enter-btn__dog-ear" />
    </router-link>
  </main>
</template>

<script>
import DogEar from "../components/DogEar.vue";

export default {
  components: {
    DogEar,
  },
  computed: {
    content() {
      return this.$t("home");
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $primary-dark;
  background-size: cover;

  &__logo {
    width: toRem(850);
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__img {
      $img-margin: toRem(42);

      width: calc(100% - #{$img-margin} * 2);
      margin: $img-margin;
    }
  }

  &__enter-btn {
    @include button-xl;
    @include shadow-1;

    position: absolute;
    bottom: toRem(200);
    left: 50%;
    transform: translateX(-50%);
    background-color: $basic-white;
    width: toRem(470);
    padding: toRem(25);
    display: flex;
    justify-content: center;
    border-radius: toRem(10);
    border: 1px solid $basic-dark-grey;

    &__dog-ear {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>
